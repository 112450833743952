import { Dialog, Transition } from '@headlessui/react';
import { useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { XIcon } from '@heroicons/react/solid';
import ThreadPage from '../ThreadPage';
var twitter = require('twitter-text');

export default function ThreadReader({
  threadModalOpen,
  setThreadModalOpen,
  threadID,
  threadCreatorSlug,
  threadCreatorID,
  creatorMeta,
}) {
  const [activeThread, setActiveThread] = useState([]);
  const [activeThreadSlug, setActiveThreadSlug] = useState('');
  const [activeThreadHasImages, setActiveThreadHasImages] = useState([]);
  const [activeThreadTweetLink, setActiveThreadTweetLink] = useState('');
  const [thread, setThread] = useState(null);
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  const getThreadFromGallery = () => {
    firebase
      .database()
      .ref('gallery')
      .child(threadCreatorSlug)
      .child(threadCreatorID)
      .child('threads')
      .child(threadID)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          setThread(snapshot.val());
        }
      });
  };

  useEffect(() => {
    getThreadFromGallery();
  }, [threadModalOpen]);

  /* 
        useEffect(() => {
            if (thread) {
                setActiveThread(thread.val().tweetArray)
                setActiveThreadSlug(thread.key)
                if (thread.val().tweetIDs) {
                    setActiveThreadTweetLink(thread.val().tweetIDs[0])
                }
                if (thread.val().imageArray) {
                    getImages(thread.val().imageArray, thread.key)
                } else {
                    setActiveThreadHasImages([])
                }
                setLoading(false)
            }
        }, [thread]) */

  const getImages = (imageArray, tslug) => {
    var imgArr = [];

    const isArr = Array.isArray(imageArray);

    if (isArr === false) {
      for (const [key, value] of Object.entries(imageArray)) {
        imgArr[key] = value;
      }
    } else {
      imgArr = imageArray;
    }

    var tempArr = [];

    imgArr.forEach((imageName, i) => {
      tempArr.push(i);

      var storageRef2 = firebase.storage().ref();
      var imgRef = storageRef2
        .child(threadCreatorID)
        .child(tslug)
        .child(imageName);

      imgRef
        .getDownloadURL()
        .then((url) => {
          // Or inserted into an <img> element
          var imgID = `tweet-image-${i}`;
          var img = document.getElementById(imgID);
          if (img !== null) {
            img.setAttribute('src', url);
          }
        })
        .catch((error) => {
          // Handle any errors
        });
    });

    setActiveThreadHasImages(tempArr);
  };

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  return (
    <Transition.Root show={threadModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={threadModalOpen}
        onClose={setThreadModalOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center md:block md:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm backdrop-filter transition-all" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:h-screen md:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden overflow-y-auto rounded-xl bg-white px-12 text-left align-bottom shadow-xl transition-all md:my-8 md:align-middle">
              <ThreadPage
                thread={thread}
                threadCreatorID={threadCreatorID}
                threadCreatorSlug={threadCreatorSlug}
                creatorMeta={creatorMeta}
                threadSlug={threadID}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
