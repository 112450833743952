import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import EssayCard from '../../Cards/essayCard';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { useRouter } from '../../SocialBlogContext';

export default function EssaySocialBlogCard({
  edit,
  isScheduled = false,
  typeshareID = null,
  removeCard,
  openEssaySelector,
  userID,
  slug,
  collection = null,
  border,
}) {
  const [activeEssay, setActiveEssay] = useState();
  const [loading, setLoading] = useState(true);
  const [customSlug, setCustomSlug] = useState('');

  const router = useRouter();

  useEffect(() => {
    userID && slug && typeshareID ? getActiveEssay() : null;
  }, [typeshareID]);

  function getActiveEssay() {
    const ref = firebase
      .database()
      .ref('gallery/' + slug)
      .child(userID)
      .child('typeshares')
      .child(typeshareID);
    ref
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          setActiveEssay(snapshot);
          if (snapshot.hasChild('preview/liveSlug')) {
            setCustomSlug(snapshot.child('preview/liveSlug').val());
          } else {
            setCustomSlug(typeshareID);
          }
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  const click = (event) => {
    event.stopPropagation();
    openEssaySelector();
  };
  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };
  const open = (event) => {
    event.stopPropagation();
    if (activeEssay.hasChild('tweetArray')) {
      //is a non migrated thread
      router.push({
        pathname: '/[slug]/threads/[thread]',
        query: { slug: slug, thread: customSlug },
      });
    } else {
      router.push({
        pathname: '/[slug]/posts/[post]',
        query: { slug: slug, post: customSlug },
      });
    }
  };

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <div className={'group relative h-full w-full'}>
      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 z-10 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => open(event)}
          onTouchStart={(event) => open(event)}
          className={` ${
            typeshareID !== null ? 'flex' : 'hidden'
          } cursor-pointer rounded-full bg-white p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-600'}
          />
        </button>
        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={
            ' rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
          }
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>

      {typeshareID !== null && !loading ? (
        <div
          className={`h-full w-full ${
            edit
              ? null
              : 'transform duration-200 hover:scale-105 active:scale-100'
          }`}
        >
          <EssayCard
            border={border}
            isScheduled={isScheduled}
            creatorSlug={slug}
            creatorID={userID}
            preview={true}
            socialBlog={true}
            typeshare={formatTS(activeEssay)}
            edit={edit}
            click={() =>
              edit
                ? null
                : isScheduled
                ? null
                : router.push({
                    pathname: '/[slug]/posts/[post]',
                    query: { slug: slug, post: customSlug },
                  })
            }
          />
        </div>
      ) : !edit ? null : (
        <div
          style={{ boxShadow: '0px 4px 24px 0px #0000000D' }}
          className={` ${
            edit
              ? 'cursor-move'
              : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
          } group relative flex h-full w-full items-center justify-center overflow-hidden  rounded-3xl border border-dashed border-gray-300 bg-gray-100`}
        >
          <button
            onClick={(event) => click(event)}
            className={`${
              typeshareID === null ? 'flex' : 'hidden'
            } transform rounded-full bg-gray-800 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl  backdrop-filter duration-200 hover:scale-105 active:scale-100`}
          >
            <p>Select Post</p>
          </button>
        </div>
      )}
    </div>
  );
}
