/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import router from 'next/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import ConnectTwitter from '../Modal/connect-twitter';
import ReconnectTwitter from '../Modal/reconnect-twitter';
import EssayCard from '../Cards/essayCard';
import EssayIconDetailed from '../Icons/essay-icon-detailed';
import EmptyStateSection from '../EmptyState';
import Button from '../Button';

export default function EssaySelectorModal({
  open,
  setOpen,
  activeBlock = null,
  posts,
  selectedEssay,
  theme,
  creatorSlug,
  creatorID,
  threads,
  next,
  fetchPosts,
}) {
  const cancelButtonRef = useRef();

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <>
      <Transition.Root initialFocus={cancelButtonRef} show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center antialiased sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-md backdrop-filter transition-all" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            >
              <div
                className={
                  'relative inline-block transform overflow-hidden rounded-2xl bg-gray-100 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:align-middle'
                }
              >
                <button
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                  className={
                    'absolute right-2 top-2 transform rounded-full border border-gray-200 p-1.5 text-gray-700 outline-none duration-200 hover:-translate-y-0.5 hover:text-gray-900 hover:outline-none focus:outline-none'
                  }
                >
                  <svg className={'h-5 w-5'} fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M17.25 6.75L6.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.75 6.75L17.25 17.25"
                    ></path>
                  </svg>
                </button>

                <div
                  className={
                    'flex flex-col items-start justify-center px-8 pt-8'
                  }
                >
                  <Dialog.Title
                    as="h3"
                    className="mb-8 text-left font-primary text-3xl font-bold text-gray-900"
                  >
                    Select a Post
                  </Dialog.Title>

                  {posts.length === 0 ? (
                    <EmptyStateSection
                      icon={<EssayIconDetailed className={'h-12 w-12'} />}
                      title={'No Published Essays'}
                      text={"You haven't published any essays yet."}
                      noCTA={true}
                    />
                  ) : (
                    <div
                      className={
                        'flex w-full flex-col items-center justify-center space-y-8'
                      }
                    >
                      <div
                        className={
                          'grid w-full grid-flow-row grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-3'
                        }
                      >
                        {posts.map((typeshare, i) => (
                          <EssayCard
                            key={typeshare.id}
                            typeshare={typeshare}
                            click={() =>
                              selectedEssay(
                                typeshare?.id,
                                typeshare?.val?.content ? 'essay' : 'thread',
                              )
                            }
                            creatorID={creatorID}
                            creatorSlug={creatorSlug}
                          />
                        ))}
                      </div>

                      <div
                        className={
                          'my-4 flex w-full flex-col items-center justify-center space-y-2 border-t border-gray-200 pt-4 text-sm font-medium text-gray-500'
                        }
                      >
                        {next ? (
                          <p>
                            Showing your {posts.length} most recent published
                            posts
                          </p>
                        ) : (
                          <p>Showing all your published posts</p>
                        )}
                        {next && (
                          <Button
                            variant={'outline'}
                            size={'mini'}
                            click={() => fetchPosts()}
                            disabled={!next}
                          >
                            Load More
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
